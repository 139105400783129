.instrument__column {
  flex: 0 0 calc(100% / 3);
  padding: 0 30px;
  display: flex;
}

.instrument {
  flex: 1 0 auto;
  padding: 40px 35px;
  border-radius: 32px;
}

.instrument__title {
  font-size: 16px;
  flex: 1 0 auto;
  margin: 0;
}

.instrument__header {
  display: flex;
  margin: 0 0 20px 0;
}

.instrument__grid {
  color: #fff;
}

.instrument__toggle-wrapper {
  align-self: flex-end;
  margin: 0 -4px;
}

.instrument__toggle {
  font-size: 12px;
  font-weight: 900;
  border-radius: 999px;
  border: 0;
  padding: 4px 9px;
  margin: 0 4px;
  cursor: pointer;
  transition: background 0.1s ease;
}

.instrument__row {
  display: flex;
  gap: 1px;
}

.instrument__cell {
  flex: 1 0 auto;
  height: 15px;
  border-radius: 3px;
  opacity: 0.6;
  transition: opacity 0.1s ease;
}

.instrument__cell--active {
  background: #fff;
}

.instrument__cell--playing {
  opacity: 1;
}

/**
 * Variants
 */

/* Drum */

.instrument--drum {
  background: rgba(215, 187, 114, 0.1);
}

.instrument--drum .instrument__title {
  color: #d7bb72;
}

.instrument--drum .instrument__toggle {
  background: #d7bb72;
}

.instrument--drum .instrument__toggle--inactive {
  background: rgba(215, 187, 114, 0.08);
}

/* Bass */

.instrument--bass {
  background: rgba(234, 144, 116, 0.1);
}

.instrument--bass .instrument__title {
  color: #ea9074;
}

.instrument--bass .instrument__toggle {
  background: #ea9074;
}

.instrument--bass .instrument__toggle--inactive {
  background: rgba(234, 144, 116, 0.08);
}

.instrument--bass .instrument__cell--active {
  background: #ea9074;
}

/* Synth */

.instrument--synth {
  background: rgba(122, 92, 209, 0.15);
}

.instrument--synth .instrument__title {
  color: #7a5cd1;
}

.instrument--synth .instrument__toggle {
  background: #7a5cd1;
}

.instrument--synth .instrument__toggle--inactive {
  background: rgba(122, 92, 209, 0.12);
}

.instrument--synth .instrument__cell--active {
  background: #7a5cd1;
}
