* {
  box-sizing: border-box;
}

html {
  font-family: "Inter", sans-serif;
  background-color: #000;
}

.header {
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 40px;
}

.header__section {
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.header__text {
  font-weight: 900;
}

.header__button {
  border: 0;
  padding: 0;
  font: inherit;
  color: inherit;
  background: transparent;
  cursor: pointer;
}

.header__button:hover {
  text-decoration: underline;
}

.header__logo {
  position: relative;
  font-weight: 900;
  font-size: 32px;
  margin: 0;
  user-select: none;
  padding-right: 25px;
}

.header__version {
  position: absolute;
  position: absolute;
  top: 5px;
  right: 24px;
  transform: translateX(100%);
  font-size: 15px;
  line-height: 1;
}

.header__overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  z-index: 1;
}

.instrument-wrapper {
  display: flex;
  margin: 0 0 30px 0;
}

.track {
  padding: 5px;
  background: #ccc;
}

.note {
  padding: 0;
  border: 0;
}

.stepActive,
.stepInactive {
  color: #fff;
  width: 30px;
  height: 30px;
}

.stepActive {
  background-color: #222;
}

.stepInactive {
  background-color: #888;
}

.stepWrap {
  position: relative;
  display: inline-block;
}

.lightActive,
.lightInactive {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lightActive {
  background-color: yellow;
}
